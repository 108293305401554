@charset "UTF-8";
/*** Import / Initialisation des variables ***/
/* Conf */
/* Functions sass */
/********************************************************************/
/*********** Fonction qui genere un svg background-image ************/
/********************************************************************/
/* Variables de couleurs */
/* ************************************************** */
/* ******************** COULEURS ******************** */
/* ************************************************** */
/* Couleurs projet */
/* utilisee pr declaration primary-color dans Settings */
/* utilisee pr declaration primary-color dans Settings */
/* Couleurs basiques (variables utilisees dans Settings) */
/* Nuances de gris (variables utilisees dans Settings) */
/* Dashboard */
/* Variables des icones */
/******************************************************************************/
/******************************************************************************/
/*********************** SVG OPTIONS D'EXPORT *********************************/
/******************************************************************************/
/******************************************************************************/
/*
	Options d'export pour rendre les SVG background-image compatibles avec IE11

	Fichier AI > Exporter la selection > paramètres (format) > SVG
		Attributs de présentation
		SVG
		Incorporer
		Noms de calques
		2
		Minimifier
		Responsive

	+ ajout de l'attribut preserveAspectRatio="xMinYMid"

	Si probleme de svg avec poplyline ou line > ouvrir le svg dans ai > selection de l'icone > Objet > tracé > Vectoriser le contour
*/
/********************/
/******* IMPORT *****/
/********************/
/* Variables de couleurs */
/********************/
/******* LAYOUT *****/
/********************/
/* Formulaires */
/* Slick slider */
/* DEFAULT */
/* Boutons */
/* Bouton LIEN */
/* Bouton ALL */
/* Bouton TXT */
/* DEFAULT */
/* Btn close */
/* DEFAULT */
/* Arrow right boutons (ex : submit, btn_all...) */
/* DEFAULT */
/* Arrow back */
/* DEFAULT */
/* Arrow reinit */
/* DEFAULT */
/* Header */
/* Loupe */
/* DEFAULT */
/* Micro */
/* DEFAULT */
/* Footer */
/* Go to top */
/* DEFAULT */
/* Fil d'ariane */
/* DEFAULT */
/* Share / bloc note / maj */
/* DEFAULT */
/* DEFAULT */
/* DEFAULT */
/* DEFAULT */
/* DEFAULT */
/* DEFAULT */
/* DEFAULT */
/* DEFAULT */
/* Reseaux */
/********************/
/***** HOME PAGE ****/
/********************/
/* Bandeau de page */
/* DEFAULT */
/* Profil */
/********************/
/***** PAGE TYPE ****/
/********************/
/* Liste a puces  */
/* DEFAULT */
/* Citation  */
/* DEFAULT */
/* Accordeon */
/* Colonne contextuelle */
/* Bloc geoloc */
/* DEFAULT */
/* DEFAULT */
/* Telechargements */
/* Consulter */
/* Liens utiles */
/********************/
/***** PAGE LISTE ***/
/********************/
/* Btn afficher la carte */
/* DEFAULT */
/* Btn afficher la liste */
/* DEFAULT */
/* Filtres */
/* DEFAULT */
/*****************************/
/***** PAGE DETAIL / POPIN ***/
/*****************************/
/* Fonts */
/* Custom mixins */
/* ************************************************** */
/* ******************** COULEURS ******************** */
/* ************************************************** */
/* Couleurs projet */
/* utilisee pr declaration primary-color dans Settings */
/* utilisee pr declaration primary-color dans Settings */
/* Couleurs basiques (variables utilisees dans Settings) */
/* Nuances de gris (variables utilisees dans Settings) */
/* Dashboard */
/* ************************************************** */
/* ********************* MIXINS ********************* */
/* ************************************************** */
/* Degrade */
/* Shadow */
/* Bloc home title */
/* Boutons */
/********************************************************************************/
/**************************** MODULE SLICK SLIDER  ******************************/
/********************************************************************************/
/*** NORMALISATION ***/
/* fix font blurry */
.off-canvas-wrapper-inner .slick-slider .slick-track {
  z-index: 1; }

.off-canvas-wrapper-inner .slick-slider .slick-track, .off-canvas-wrapper-inner .slick-slider .slick-list {
  transform: none; }

/* Slick slider Flex like */
.slick-slider {
  display: flex !important; }

.slick-track, .slick-track .slick-slide > *, .slick-track .slick-slide > * > * {
  height: 100%; }

.bloc_home .slick-slider .slick-list {
  margin: 0 -1.5rem; }

/* Bugfix si nb plus petit que la conf js */
.slick-track > li, .slick-track > div, .slick-track > article {
  font-size: 1em;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

/*** REINIT LIEN ***/
.content_page .slick-track a, .slick-track a {
  display: block;
  border: 0;
  padding: 0; }

.slick-track {
  margin: 0 auto; }

/*** ARROW ***/
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 5rem;
  height: 5rem;
  padding: 0;
  color: transparent;
  font-size: inherit;
  z-index: 3;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  background: #ffffff;
  border: 0.2rem solid #ffd639;
  border-radius: 50%; }

.slick-arrow.slick-prev {
  left: 0; }

.slick-arrow.slick-next {
  right: 0; }

.slick-arrow.slick-disabled {
  display: none !important; }

.slick-arrow.slick-arrow::before {
  content: '';
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 1.4rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  transition: 0.3s ease-in-out; }

.slick-arrow.slick-prev::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg preserveAspectRatio='xMinYMid' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.65 14'%3E%3Cpath d='M8.65,12.35,3.3,7,8.65,1.64,7,0,0,7l7,7Z' fill='%232f2722'/%3E%3C/svg%3E"); }

.slick-arrow.slick-next::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg preserveAspectRatio='xMinYMid' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.65 14'%3E%3Cpath d='M0,1.65,5.34,7,0,12.36,1.65,14l7-7-7-7Z' fill='%232f2722'/%3E%3C/svg%3E"); }

/* etat hover - focus */
.slick-arrow.slick-arrow:hover::before,
.slick-arrow.slick-arrow:focus::before {
  opacity: 0.7;
  /*box-shadow:0 0 0 0.5rem $black;*/ }

/*** LEGENDE ***/
.slick-slider figcaption {
  line-height: 1.7em;
  padding: 0.5rem 0; }

/********************************************************************************/
/******************************** RESPONSIVE ************************************/
/********************************************************************************/
