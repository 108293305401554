/*** Import / Initialisation des variables ***/

	/* Conf */
	@import '../global/settings';

/********************************************************************************/
/**************************** MODULE SLICK SLIDER  ******************************/
/********************************************************************************/

	/*** NORMALISATION ***/
		/* fix font blurry */
		.off-canvas-wrapper-inner .slick-slider .slick-track {z-index:1;}
		.off-canvas-wrapper-inner .slick-slider .slick-track, .off-canvas-wrapper-inner .slick-slider .slick-list {transform:none;}

	/* Slick slider Flex like */
		.slick-slider {display: flex !important;}
		.slick-track, .slick-track .slick-slide > *, .slick-track .slick-slide > * > * {height: 100%;}

	.bloc_home .slick-slider .slick-list {margin:0 -1.5rem;}

	/* Bugfix si nb plus petit que la conf js */
		.slick-track > li, .slick-track > div, .slick-track > article {font-size:1em; -ms-flex:0 0 100%; flex:0 0 100%; max-width:100%;}

	/*** REINIT LIEN ***/
		.content_page .slick-track a, .slick-track a {display:block; border:0; padding:0;}
		.slick-track {margin:0 auto;}

	/*** ARROW ***/
		.slick-arrow {position:absolute; top:50%; transform:translateY(-50%); width:5rem; height:5rem; padding:0; color:transparent; font-size:inherit; z-index:3; cursor:pointer; transition:transform 0.3s ease-in-out; background:$cim_white; border:0.2rem solid $yellow; border-radius:50%;}
		.slick-arrow.slick-prev {left:0;}
		.slick-arrow.slick-next {right:0;}
		.slick-arrow.slick-disabled {display:none !important;}
		
		.slick-arrow.slick-arrow::before {content:''; background-repeat:no-repeat; background-position:center center; background-size:auto 1.4rem; position:absolute; top:0; left:0; width:100%; height:100%; z-index:2; cursor:pointer; transition:0.3s ease-in-out;}
		.slick-arrow.slick-prev::before {@include slick_prev($cim_black);}
		.slick-arrow.slick-next::before {@include slick_next($cim_black);}
		/* etat hover - focus */
			.slick-arrow.slick-arrow:hover::before,
			.slick-arrow.slick-arrow:focus::before {opacity:0.7; /*box-shadow:0 0 0 0.5rem $black;*/}

	/*** LEGENDE ***/
		.slick-slider figcaption {line-height:1.7em; padding:0.5rem 0;}

		
/********************************************************************************/
/******************************** RESPONSIVE ************************************/
/********************************************************************************/

	@media screen and #{breakpoint(large down)} {}

	@media screen and #{breakpoint(medium down)} {}

	@media screen and #{breakpoint(small down)} {}			
